footer{
    background-color: var(--color-primary);
    text-align: center;
    font-size: 0.9rem;
    padding: 3rem 0;
    margin-top: 3rem;
}

footer a{
    color: var(--color-bg);
}

.footer_title{
    color: var(--color-bg);
    margin-bottom: 1rem;
}

.permalinks{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 2rem;
}
.footer__socials{
    justify-content: center;
    display: flex;
    gap: 2rem;
    margin-bottom: 0.5rem;
}

.footer__socials a{
    background-color: var(--color-bg);
    color: var(--color-light);
    padding: 0.8rem;
    display: flex; 
    border-radius: 0.7rem;
    border: 1px solid transparent;
}

@media screen and (max-width: 600px) {
    .permalinks{
        flex-direction: column;
        gap: 1.5rem;
    }
    .footer__socials{
        flex-direction: row;
    }
}