.container.contact__container{
    display: flex;
    justify-content: center;
    width: 100%;
}

form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    width: 70%;
}

input, textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 1px solid transparent;
    border-color: var(--color-light);
    resize: none;
    color: var(--color-white);
}

@media screen and (max-width: 1024px) {
    .container.contact__container{
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 600px) {
    .container.contact__container{
        width: var(--container-width-sm);
    }
}