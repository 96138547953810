.projects__container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.project{
    background: var(--color-bg);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    border-color: var(--color-light);
    transition: var(--transition);
}

.project:hover {
	box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);	
	background-color: rgba(255, 255, 255, .15);
	border-radius: 2rem;
	backdrop-filter: blur(5px);
}

.project__img{
    border-radius: 1.5rem;
    overflow: hidden;
}

.project__cta{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 1rem;
}

@media screen and (max-width: 1024px) {
    .projects__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

@media screen and (max-width: 600px) {
    .projects__container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .project{
        margin: 1rem;
    }
}