header {
    height: 50vh;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

.name{
    text-transform: uppercase;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0004);
    line-height: 0.70rem;
    outline: none;
    animation: animate 7s linear infinite;
}

@keyframes animate{
    0%, 18%, 20%, 50.1%, 60%, 65.1%, 80%, 90%, 90.1%
    {
        color:#424949;
        text-shadow: none;
    }
    18.1%, 20.1%, 30%, 50%, 60.1%, 65%, 80.1%, 90%, 92.1%, 100%{
        color: rgba(255, 255, 255, 0.877);
        text-shadow: 0 0 10px #AAB7B8,
        0 0 20px #AAB7B8,
        0 0 40px #AAB7B8,
        0 0 80px #AAB7B8,
        0 0 160px #AAB7B8;
    }
}

.social__medias {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    top: 5rem;
}

.me {
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 5rem;
}

.scroll__down {
    position: absolute;
    right: -2.3rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
    top: 8rem;
}

@media screen and (max-width: 1024px) {
    header{
        height: 42vh;
    }
}

@media screen and (max-width: 600px) {
    header{
        height: 50vh;
    }
    .social__medias,
    .scroll__down{
        display: none;
    }
}